import { fetchMembership, updateMembership } from '@/api/user'
import { useNotifyErrors } from '@/composables'
import { ref } from '@vue/composition-api'

export default function useMembershipView() {
  const membership = ref(null)

  const loadMembership = id =>
    fetchMembership(id)
      .then(({ data }) => {
        membership.value = data.data
      })
      .catch(useNotifyErrors)

  return { membership, loadMembership, updateMembership }
}
