<template>
  <div
    v-if="membership"
    id="membership-view"
  >
    <v-row>
      <v-col
        cols="12"
        md="5"
      >
        <v-card>
          <v-card-title>Membership Details</v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <span>Status</span>
              <v-chip>
                {{ membership.status }}
              </v-chip>
            </div>

            <div class="d-flex justify-space-between">
              <span>Active</span>
              <b>
                {{ membership.active ? 'Yes' : 'No' }}
              </b>
            </div>

            <div class="d-flex justify-space-between">
              <span>User</span>
              <b>
                User: {{ membership.user.email }}
              </b>
            </div>

            <div class="d-flex justify-space-between">
              <span>Total Recursion</span>
              <span>
                Renewed <b>{{ membership.recursion }}</b> times.
              </span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Pass Details</v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <span>Name</span>
              <b>{{ t(pass.title) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <v-card>
          <v-card-title>Next Billing & Dates</v-card-title>

          <v-card-text>
            <div class="d-flex justify-space-between">
              <span>Next Billing</span>
              <b>
                {{ membership.next_billing_at | dateTime }}
              </b>
            </div>

            <div class="d-flex justify-space-between">
              <span>Created at</span>
              <b>
                {{ membership.created_at | dateTime }}
              </b>
            </div>

            <div class="d-flex justify-space-between">
              <span>Last Updated at</span>
              <b>
                {{ membership.updated_at | dateTime }}
              </b>
            </div>

            <div class="d-flex justify-space-between">
              <span>Ended at</span>
              <b v-if="membership.ended_at">
                {{ membership.ended_at | dateTime }}
              </b>
            </div>

            <template v-if="['started', 'free_trial'].includes(membership.status)">
              <v-btn
                v-if="!membership.ending"
                color="error"
                @click="updateTo({ ending: 1 })"
              >
                Stop renewal
              </v-btn>

              <v-btn
                v-if="membership.ending"
                color="success"
                @click="updateTo({ ending: 0 })"
              >
                Continue renewal
              </v-btn>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'

import { computed, onMounted, ref } from '@vue/composition-api'

import { t } from '@/plugins/i18n'
import { dateTime } from '@core/utils/filter'
import useMembershipView from './useMembershipView'

export default {
  filters: { dateTime },
  setup() {
    const { membership, loadMembership, updateMembership } = useMembershipView()

    const pass = computed(() => membership.value?.group.plan_pass)

    const reloadMembership = () => loadMembership(router.currentRoute.params.id)

    onMounted(reloadMembership)

    const aside = ref(false)
    const setAside = () => {
      aside.value = true
    }

    const updateTo = async updates => {
      await updateMembership(membership.value.id, updates)
      await reloadMembership()
    }

    return {
      membership,
      pass,
      t,

      loadMembership,
      updateMembership,
      updateTo,

      setAside,
      aside,
    }
  },
}
</script>
