var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.membership ? _c('div', {
    attrs: {
      "id": "membership-view"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Membership Details")]), _c('v-card-text', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Status")]), _c('v-chip', [_vm._v(" " + _vm._s(_vm.membership.status) + " ")])], 1), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Active")]), _c('b', [_vm._v(" " + _vm._s(_vm.membership.active ? 'Yes' : 'No') + " ")])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("User")]), _c('b', [_vm._v(" User: " + _vm._s(_vm.membership.user.email) + " ")])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Total Recursion")]), _c('span', [_vm._v(" Renewed "), _c('b', [_vm._v(_vm._s(_vm.membership.recursion))]), _vm._v(" times. ")])])])], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_vm._v("Pass Details")]), _c('v-card-text', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Name")]), _c('b', [_vm._v(_vm._s(_vm.t(_vm.pass.title)))])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Next Billing & Dates")]), _c('v-card-text', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Next Billing")]), _c('b', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.membership.next_billing_at)) + " ")])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Created at")]), _c('b', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.membership.created_at)) + " ")])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Last Updated at")]), _c('b', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.membership.updated_at)) + " ")])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Ended at")]), _vm.membership.ended_at ? _c('b', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.membership.ended_at)) + " ")]) : _vm._e()]), ['started', 'free_trial'].includes(_vm.membership.status) ? [!_vm.membership.ending ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.updateTo({
          ending: 1
        });
      }
    }
  }, [_vm._v(" Stop renewal ")]) : _vm._e(), _vm.membership.ending ? _c('v-btn', {
    attrs: {
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.updateTo({
          ending: 0
        });
      }
    }
  }, [_vm._v(" Continue renewal ")]) : _vm._e()] : _vm._e()], 2)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }